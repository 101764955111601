import axios from "axios";
export const post = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      console.log("ressssss",response);
      if (response.data.status) {
        return {
          success: true,
          data: response.data.data,
          message: response.data.message,
        };
      } else {
        return {
          success: false,
          data: response.data.data,
          message: response.data.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        data: [],
        message: error.message || "something_went_wrong",
      };
    }
  };
  
  export const get = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.data.status) {
        return {
          success: true,
          data: response.data.data,
          count:response.data.count,
          totalPage:response.data.total_page,
          message: response.data.message,
        };
      } else {
        return {
          success: false,
          count:response.data.count || 0,
          totalPage:response.data.total_page ||0,
          data: response.data.data,
          message: response.data.message,
        };
      }
    } catch (error) {
      return {
        success: false,
        count:0,
        totalPage:0,
        data: [],
        message: error.message,
      };
    }
  };