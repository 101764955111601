import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload, Table, Input, Button, Alert } from 'antd';
import { uploadImageApiHandler } from '../Service/Demo.service';
import { errorNotification, successNotification } from '../Helper/Notification';
import { useRef } from 'react';
import { Tabs } from 'antd';
import { Radio } from 'antd';
import { useNavigate } from 'react-router-dom';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ImageUploadAndTable = () => {
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [countdown, setCountdown] = useState(null);
  const [totalData, setTotalData] = useState([]);
  const [productData, setProductData] = useState([]); // [{}
  const [height, setHeight] = useState("");
  const [image, setImage] = useState("");
  const [value, setValue] = useState('');
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [heights, setHeights] = useState("");
  const [hasPhoto, setHasPhoto] = useState(false);
  const [environment, setEnvironment] = useState(''); // State to store the camera environment ['user', 'environment'
  const [photo, setPhoto] = useState(''); // State to store the captured image
  const [cameraOpen, setCameraOpen] = useState(false);
  const [messagePreview, setMessagePreview] = useState();
  const [successMessagePreview, setSuccessMessagePreview] = useState();
  const [loading, setLoading] = useState(false);
  const [Submit, setSubmit] = useState(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const dataUploadhandler = async () => {
    try {
      setLoading(true);
      if (!image) {
        return errorNotification("Please upload an image");
      }

      if (value == "") {
        return errorNotification("Please select a gender");
      }
      const formData = new FormData();
      formData.append("image", image);
      formData.append("known_height", '5.6');
      formData.append("gender", value);
      const { data, message, success } = await uploadImageApiHandler(formData);
      if (success) {
        setTotalData(data);
        setProductData(data.filter((item) => item.product_data));
        //remove privious message
        setMessagePreview();
        setSuccessMessagePreview(message);
        setSubmit(true);
      } else {
        setTotalData([]);
        console.log("2");
        setSubmit(true);
        // errorNotification(message);
        setSuccessMessagePreview();
        setMessagePreview(message)
      }

    } catch (err) {

    } finally {
      setLoading(false);
    }
  }

  const handleChange = ({ fileList: newFileList }) => {
    setImage(newFileList[0]?.originFileObj);
    setFileList(newFileList.slice(-1)); // Keep only the last uploaded file

    //if image remove then set data blank 
    if (newFileList.length == 0) {
      setCountdown(null);
      setTotalData([]);
      setProductData([]);
      setPreviewImage("");
    }

  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Parameters',
      dataIndex: 'parameters',
      key: 'parameters',
    },
  ];

  const columns1 = [
    {
      title: 'Image',
      dataIndex: 'img_link',
      key: 'img_link',
      render: (text, record) => (
        record && record?.img_link ? <img
          src={record.img_link}
          alt={record.title}
          style={{ width: '100px' }} // Set a fixed width or as needed
        /> : <div style={{
          width: '100px',
          height: '100px',
          backgroundColor: 'lightgray',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          No Image
        </div>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Product Details',
      dataIndex: 'product_details',
      key: 'product_details',
      render: (text, record) => (
        <div>
          <div>
            {record && record.product_details && record.product_details.replace(/ : /g, " :- ").split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      ),

    },
  ];

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const reset = () => {
    setFileList([]);
    setTotalData([]);
    setProductData([]);
    setSuccessMessagePreview();
    setMessagePreview();
    setImage("");
    setSubmit(false);
    setValue("");
    setPreviewImage("");
  }
  const getVideo = (env) => {
    //set photoref to null
    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: env || environment // Request the back camera
      }
    })
      .then(stream => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setCameraOpen(true);
      })
      .catch(err => {
        setCameraOpen(false);
        console.error("error:", err);
      });
  };

  const takePhoto = () => {
    //get window width and height
    let timeLeft = 5;
    setCountdown(timeLeft);
    const countdownInterval = setInterval(() => {
      timeLeft--;
      if (timeLeft < 0) {
        clearInterval(countdownInterval); // Stop the interval
        setCountdown(null);
      } else {
        setCountdown(timeLeft);
      }
    }, 1000);


    setTimeout(() => {
      const windowwidth = window.innerWidth;
      const width = windowwidth > 600 ? 600 : 264
      const height = windowwidth > 600 ? 500 : 340.16

      let video = videoRef.current;
      let photo = photoRef.current;

      if (!video || !photo) {
        console.error("Camera or photo elements are not available.");
        return; // Exit the function if elements are not available
      }
      photo.width = width;
      photo.height = height;

      let ctx = photo.getContext('2d');
      ctx.drawImage(video, 0, 0, width, height);
      const imageDataUrl = photo.toDataURL('image/png');
      setHeights(photo.height);
      console.log("imageDataUrl", imageDataUrl);
      setPreviewImage(imageDataUrl);
      setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: imageDataUrl }]);
      setHasPhoto(true);
      closeCamera();
    }, 5000);
  };


  const closeCamera = () => {
    setCountdown(null);
    const videoElement = videoRef.current;
    if (videoElement && videoElement.srcObject) {
      const tracks = videoElement.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoElement.srcObject = null;
    }
    setCameraOpen(false);
    setHasPhoto(false);
  };

  const changeEnvironment = () => {
    setCountdown(null);
    setEnvironment(environment == 'environment' ? 'user' : 'environment');
    closeCamera();
  }

  useEffect(() => {
    if (environment != '') {
      getVideo();
    }
  }, [environment]);
  function base64ToFile(base64, fileName, mimeType) {
    // Split the base64 string into parts
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1] || mimeType; // Provide mime type if not present in base64 string
    const raw = window.atob(parts[1]); // Decode Base64 string
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength); // Create a uint8Array

    // Populate the array with character codes from the decoded string
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    // Create a Blob with the Uint8Array
    const blob = new Blob([uInt8Array], { type: contentType });
    setImage(blob);

    // Return a File object from the Blob
    return new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now() // Optionally set the last modified date to now
    });
  }


  useEffect(() => {
    if (previewImage && previewImage !== '') {

      const res = base64ToFile(previewImage, 'image.png', 'image/png');
      setImage(res);
    }
  }, [previewImage]);


  const onChangeTab = (key) => {
    console.log(key);
  };

  const locale = {
    emptyText: Submit ? 'Body pose is not correct, please check image requirement or your size products are not available.' : '',
  };
  const items = [
    {
      key: '1',
      label: 'Right Fit',
      children: <Table dataSource={productData && productData[0] && productData[0].product_data && productData[0].product_data.right_data && productData[0].product_data.right_data} columns={columns1} pagination={true} locale={locale} />,
    },
    {
      key: '2',
      label: 'Loose Fit',
      children: <Table dataSource={productData && productData[0] && productData[0].product_data && productData[0].product_data.lose_data && productData[0].product_data.lose_data} columns={columns1} pagination={true} locale={locale} />,
    },
    {
      key: '3',
      label: 'Tight Fit',
      children: <Table dataSource={productData && productData[0] && productData[0].product_data && productData[0].product_data.fit_data && productData[0].product_data.fit_data} columns={columns1} pagination={true} locale={locale} />,
    },
  ];
  const onClose = (e) => {
    setMessagePreview()
    setSuccessMessagePreview()
    reset();
    // console.log(e, 'I was closed.');
  };

  console.log("previewImage", image);
  return (
    <div style={{ maxWidth: '1000px', margin: '50px auto' }}>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: '20px', gap: '10px' }}>
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '15px' }}>
          <div style={{ position: 'absolute', left: 0 }}>
            <Button type="primary" onClick={() => navigate('/')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="white" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20z" /></svg>
            </Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <h1 style={{ margin: 0 }}>AI Body Measurement</h1>
            <div classNames='info_button'>
              <Button type="primary" danger onClick={() => navigate('/')} >
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 1024 1024"><path fill="black" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64m67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344M590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.99 12.99 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z" /></svg>
                  <div>Know More</div>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}>
          <div className="Camera">
            <div className="video-container" style={{ position: 'relative' }}>
              {countdown !== null && countdown != 0 && cameraOpen && !image &&
                <div style={{
                  position: 'absolute',
                  top: '50%',  // Center vertically
                  left: '50%', // Center horizontally
                  transform: 'translate(-50%, -50%)', // Adjust for exact centering
                  zIndex: 10,
                  color: 'white',
                  fontSize: '100px', // Large font size for visibility
                  // fontWeight: 'bold', // Bold to enhance readability
                  textShadow: '0 0 1px black', // Text shadow for contrast against potentially light backgrounds
                }}>
                  {countdown}
                </div>
              }

              <video ref={videoRef} autoPlay playsInline muted style={{ width: '100%', display: `${cameraOpen ? 'block' : 'none'}` }}></video>

            </div>
            {/* <video ref={videoRef} autoPlay playsInline muted style={{ width: '100%', display: `${cameraOpen ? 'block' : 'none'}` }}></video> */}
            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
              {(!cameraOpen && !image) && <Button type="primary" onClick={getVideo}>Open Camera And Take Picture</Button>}

              {cameraOpen &&
                <>
                  <Button type="primary" onClick={closeCamera}>Close Camera</Button>
                  <Button type="primary" onClick={takePhoto}>Take Photo</Button>
                  <Button type="primary" onClick={() => changeEnvironment()}>Change Camera</Button>
                </>
              }

              <canvas ref={photoRef} style={{ display: 'none' }}></canvas>
            </div>
          </div>

          {!cameraOpen && !image && <div>or</div>
          }
          {!cameraOpen &&
            <Upload
              action="https://sizesutraapi.mobiginie.com/file/"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList && fileList.length >= 1 ? null : uploadButton}
            </Upload>
          }

          <Image.PreviewGroup
            preview={{
              visible: previewOpen,
              onVisibleChange: setPreviewOpen,
            }}
          >
            <Image
              src={previewImage}
              style={{ display: 'none' }}
            />
            <img
              src={previewImage}
              style={{ display: 'none' }}
            />
          </Image.PreviewGroup>
          <div>
            {/* //add radio button here for gender */}
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={"Men"}>Male</Radio>
              <Radio value={"Women"}>Female</Radio>
            </Radio.Group>

          </div>
          {/* <div>
          <Input placeholder="Enter Height" value={height} onChange={(event) => setHeight(event.target.value)} />
        </div> */}
          {successMessagePreview &&
            <Alert
              message={successMessagePreview}
              // description=
              type="success"
              closable={{
                'aria-label': 'close',
                closeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 20 20"><path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z" /></svg>,
              }}
              onClose={onClose}
            />
          }
          {messagePreview &&
            <Alert
              message={messagePreview}
              // description=
              type="error"
              closable={{
                'aria-label': 'close',
                closeIcon: <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 20 20"><path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z" /></svg>,
              }}
              onClose={onClose}
            />
          }
          {/* <div className=''>{messagePreview}</div> */}
          <div
          //add gap between buttons
          >
            <Button type="primary" disabled={loading} onClick={() => dataUploadhandler()}>Submit </Button>
            <Button style={{ marginLeft: "12px" }} disabled={loading} onClick={() => reset()}>Reset </Button>

          </div>
        </div>
      </div>
      <Table dataSource={totalData} columns={columns} pagination={false}
      />
      <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />

    </div >
  );
};

export default ImageUploadAndTable;
