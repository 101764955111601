import React from 'react'
import Demo from '../assets/image.jpg';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  return (
    <div style={{ maxWidth: '1000px', margin: '50px auto', height: '100%' }}>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px', height: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '15px' }}>
          <h1 style={{ margin: 0 }}>AI Body Measurement</h1>
          <div>
            <Button type="primary" onClick={() => navigate('/measurement')}>Calculate Measurement</Button>
          </div>
        </div>
        <div style={{ border: '1px solid #c1c1c1', padding: '20px', borderRadius: '20px' }}>
          <div style={{ display: 'flex', gap: '50px', alignItems: 'center', }}>
            <div>
              <img src={Demo} style={{ width: '350px' }} alt='' />
            </div>
            <div>
              <div>
                <h4>Step-by-Step Guide for Capturing Proper Images for accurate Body Measurements.</h4>
                <div>
                  <div class="instruction-list">
                    <h5>Position and Angle:</h5>
                    <ul>
                      <li>Stand directly in front of the camera.</li>
                      <li>Ensure the camera is positioned straight at your chest level, not angled up or down.</li>
                      <li>Make sure your full body is visible in the frame, from head to toe.</li>
                    </ul>
                  </div>

                  <div class="instruction-list">
                    <h5>Posture:</h5>
                    <ul>
                      <li>Keep both arms straight down at your sides.</li>
                      <li>Stand with your legs straight and slightly apart.</li>
                    </ul>
                  </div>

                  <div class="instruction-list">
                    <h5>Clothing:</h5>
                    <ul>
                      <li>Wear fitted clothing that closely hugs your body.</li>
                      <li>Avoid loose clothing that could obscure the shape of your joints or body lines.</li>
                      <li>Ensure the clothing is minimal and simple, without large embellishments or textures.</li>
                    </ul>
                  </div>

                  <div class="instruction-list">
                    <h5>Lighting and Environment:</h5>
                    <ul>
                      <li>Choose a well-lit area to ensure there are no shadows or harsh lighting on your body.</li>
                      <li>The background should be plain and uncluttered.</li>
                    </ul>
                  </div>

                  <div class="instruction-list">
                    <h5>Hair and Accessories:</h5>
                    <ul>
                      <li>Tie back long hair or arrange it so that it does not cover any part of your neck, shoulders, or upper arms.</li>
                      <li>Remove any large jewelry or accessories that could cover your body parts or be mistaken as part of your body.</li>
                    </ul>
                  </div>

                  <div class="instruction-list">
                    <h5>Camera Distance:</h5>
                    <ul>
                      <li>The camera should be placed at a distance where your entire body is visible without being too far away, typically 6 to 10 feet depending on the camera's field of view.</li>
                    </ul>
                  </div>

                  <div class="instruction-list">
                    <h5>Final Check:</h5>
                    <ul>
                      <li>Before taking the photo, do a final check to ensure everything is in place. Your body should be centered, the clothing adjusted, and the camera set correctly.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: '20px auto 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type="primary" onClick={() => navigate('/measurement')}>Calculate Measurement</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home