import { ToastContainer } from 'react-toastify';
import './App.css';
import "react-toastify/dist/ReactToastify.css";
import ImageUploadAndTable from './Components/ImageUploadAndTable';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from './Components/Home';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/measurement" element={<ImageUploadAndTable />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
